<template>
  <v-dialog v-model="dialog" :persistent="true" max-width="35%">
    <template>
      <v-card>
        <v-card-title class="text-left pb-6">{{ `${$tc('title.atencao', 1)}!` }}</v-card-title>
        <v-card-text>
          <v-row>
            <span class="pl-3" v-if="existeFluxosImpactadosPorInativacao" v-html="`${$t('message.inativacaoUsuarioFornecedorFluxosPendentes', {
                nome: nomeFornecedor, quantidadeCampanha: quantidadeCampanha, quantidadeApuracao: quantidadeApuracao,
                quantidadeContrato: quantidadeContrato, numeroFornecedoresAprovadores: numeroFornecedoresAprovadores,
            })}`"></span>
            <span class="pl-3" v-html="`${$t('message.inativacaoUsuarioFornecedorQuantidadeAprovadores', {
                numeroFornecedoresAprovadores: numeroFornecedoresAprovadores,
            })}`"></span>
          </v-row>
        </v-card-text>
        <v-card-actions >
          <v-spacer></v-spacer>
          <v-btn id="nao" color="primary darken-1" text @click.native="disagree">
          {{$t('label.nao')}}
        </v-btn>
        <v-btn id="sim" color="primary darken-1" text @click.native="agree">
          {{$t('label.sim')}}
        </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
  name: 'ModalDadosFluxosDeAprovacaoImpactadosPorInativacaoUsuario',
  props: {
    existeFluxosImpactadosPorInativacao: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      usuarioResource: this.$api.usuario(this.$resource),
      dialog: false,
      quantidadeCampanha: 0,
      quantidadeApuracao: 0,
      quantidadeContrato: 0,
      numeroFornecedoresAprovadores: 0,
      nomeFornecedor: '',
    };
  },
  methods: {
    open(dados) {
      this.preencheCampos(dados);
      this.dialog = true;
    },
    disagree() {
      this.idUsuario = null;
      this.quantidadeCampanha = 0;
      this.quantidadeApuracao = 0;
      this.quantidadeContrato = 0;
      this.numeroFornecedoresAprovadores = 0;
      this.nomeFornecedor = '';
      this.dialog = false;
      this.$emit('disagree');
    },
    agree() {
      this.$emit('agree');
      this.disagree();
    },
    preencheCampos(dados) {
      this.quantidadeCampanha = dados.dadosFluxosAprovacaoImpactados.quantidadeCampanha ? dados.dadosFluxosAprovacaoImpactados.quantidadeCampanha : 0;
      this.quantidadeApuracao = dados.dadosFluxosAprovacaoImpactados.quantidadeApuracao ? dados.dadosFluxosAprovacaoImpactados.quantidadeApuracao : 0;
      this.quantidadeContrato = dados.dadosFluxosAprovacaoImpactados.quantidadeContrato ? dados.dadosFluxosAprovacaoImpactados.quantidadeContrato : 0;
      this.nomeFornecedor = dados.nomeFornecedor;
      this.numeroFornecedoresAprovadores = dados.dadosFluxosAprovacaoImpactados.numeroFornecedoresAprovadores
        ? dados.dadosFluxosAprovacaoImpactados.numeroFornecedoresAprovadores : 0;
    },
  },
};
</script>
<style lang="scss">
.justify {
  text-align: justify;
}
</style>
